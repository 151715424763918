import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../tools/StringTool';
import * as StringTool from '../tools/StringTool';
import { settingObj } from '../store/reducers/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'

export default function PayResultPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [result, setResult] = React.useState({});
    const settingData = useSelector(state => state.settingData);
    const [num, setNum] = React.useState(10);
    let timer;
    let _num = 10;

    React.useEffect(() => {
        setResult(state);

        //开始timer
        if (state && state.orderStatus == 0) { //处理中
            timer = setInterval(() => {
                _num--;
                setNum(_num);
                if (_num <= 0) {
                    startRefresh();
                }
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        }
    }, []);

    function startRefresh() {
        clearInterval(timer);
        navigate(`/queryResult?outTradeNo=${state.orderId}`);
    }

    function onBackClick() {
        const gid = StringTool.getSessionString(StringTool.GameId);
        if (StringTool.isEmpty(gid)) {
            navigate('/');
        } else {
            navigate(`/paymentList?gameId=${gid}`);
        }
    }

    function getImgName(status) {//0处理中，1成功，2失败
        if (status == 0) {
            return 'processing.png';
        }
        if (status == 1) {
            return 'success3.png';
        }
        return 'error_icon.png';
    }

    function getTitle(status) {
        if (status == 0) {
            return settingObj.languageDic['lang1'];
        }
        if (status == 1) {
            return settingObj.languageDic['lang2'];
        }
        return settingObj.languageDic['lang3'];
    }

    function getMsg(status) {
        if (status == 0) {
            return settingObj.languageDic['lang4'];
        }
        if (status == 1) {
            return settingObj.languageDic['lang5'];
        }
        return settingObj.languageDic['lang6'];
    }

    return (
        <Stack sx={{
            position: 'absolute',
            height: 1,
            width: 1,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {state &&
                <Stack sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: '280px', sm: '330px' }
                }}>

                    <Box component={'img'}
                        src={getImgPath(getImgName(result ? result.orderStatus : 2))}
                        sx={{
                            width: '65px',
                            height: '65px',
                        }}
                    />

                    <Typography sx={{
                        color: 'white',
                        mt: 4,
                        mb: 2,
                        fontSize: 18,
                    }}>
                        {getTitle(result ? result.orderStatus : 2)}
                    </Typography>

                    <Typography sx={{
                        fontSize:17,
                        color: 'white',
                        mb: 1,
                        display: (result && result.orderStatus == 0) ? 'flex' : 'none',
                    }}>
                        {settingData.languageDic['lang13']} {num}s
                    </Typography>

                    <ResultItem title={settingData.languageDic['lang8']} detail={result && result.productName} />

                    <ResultItem title={settingData.languageDic['lang9']} detail={result && result.quantity} />

                    <ResultItem title={settingData.languageDic['lang7']} detail={result && result.orderId} />

                    <ResultItem title={settingData.languageDic['lang10']} detail={result && getMsg(result.orderStatus)} />

                    <Box component={'div'}
                        onClick={onBackClick}
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '4px',
                            width: 1,
                            height: '40px',
                            lineHeight: '40px',
                            textAlign: 'center',
                            color: 'white',
                            bgcolor: '#3A7BFC',
                            fontSize: 15,
                            mt: 3,
                            mb: 10,
                        }}>
                        {settingData.languageDic['lang11']}
                    </Box>
                </Stack>
            }

            {!state &&
                <Box
                    color={'#D0CDE4'}
                    fontSize={{ xs: 12, sm: 16 }}
                >
                    No Payment result
                </Box>
            }

        </Stack>
    );
}

function ResultItem(props) {

    return (
        <Stack direction={'row'} width={1} color={'white'} py={1} fontSize={11}>
            <Typography sx={{
                color: '#A5ABC1',
                fontSize: 14,
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                color: '#D0CDE4',
                flex: 1,
                textAlign: 'end',
                fontSize: 14,
            }}>
                {props.detail}
            </Typography>
        </Stack>
    );

}