
import LangJsonFile from './language.json';

export const isProduction = process.env.NODE_ENV === 'production';

export function isEmpty(str) {
    if (str === null || str === undefined || !str || str === "" || 'undefined' === str || 'null' === str) {
        return true;
    }
    return false;
}

export function isEmail(str) {
    if (isEmpty(str) || str.length < 1 || str.length > 255) {
        return false;
    }
    return str.match('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}');
}

export function isPassword(str) {
    if (isEmpty(str)) {
        return false;
    }
    return str.match('^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{8,30}$');
}

export function isVerifyCode(str) {
    if (isEmpty(str)) {
        return false;
    }
    return str.match('[0-9]{6}');
}

export function print(str) {
    if (typeof window !== 'undefined' && !isProduction) {
        console.log(`-----APJ Start------\n\n${str}\n\n-----APJ End------`);
    }
}

export function saveLocalString(key, value) {
    if (typeof window !== 'undefined') {
        localStorage.setItem(key, value);
    }
}

export function saveSessionString(key, value) {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value);
    }
}

export function getLocalString(key) {
    if (typeof window !== 'undefined') {
        const str = localStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function getSessionString(key) {
    if (typeof window !== 'undefined') {
        const str = sessionStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function removeLocalString(key) {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(key);
    }
}

export function clearLocalString() {
    if (typeof window !== 'undefined') {
        localStorage.clear();
    }
}



export function searchLanguageData(value) {
    const list = getLanguageList();
    for (const i in list) {
        if (list[i].lang == value) {
            return list[i];
        }
    }
    return null;
}


export function getLanguageDic(languageValue) {
    for (const key in LangJsonFile) {
        if (key == languageValue) {
            return LangJsonFile[languageValue];
        }
    }
    return null;
}

export function getPayWayList() {
    return ['RZ', 'PM', 'XS'];
}

export function gameList() {
    return [
        {
            gameId: 'sfd',
            name: 'Street Fighter: Duel',
            appKey: '9p9AwN4kV9G94c6R',
            appChannelKey: '43m688t05j0y857k',
            icon: 'sfd_icon.png'
        }
        // ,
        // {
        //     gameId: 'overlord',
        //     name: 'Lord of Nazarick',
        //     appKey: 'L7xAj3jF9WHGLd75',
        //     appChannelKey: 'b0q1g4hxj73qw644',
        //     icon: 'overlord_icon.png'
        // },
    ];
}

export function searchGame(gameId) {
    const ls = gameList();
    for (const md of ls) {
        if (md.gameId === gameId) {
            return md;
        }
    }
    return null;
}

export function getLanguageList() {
    const languageList = [
        { name: 'English', lang: 'en' },
        { name: '简体中文', lang: 'cn' },
        { name: '繁體中文', lang: 'cht' },
    ];
    return languageList;
}

export function getLocalLanguage() {
    let localLanguage = getLocalString(Language);
    if (isEmpty(localLanguage)) {
        localLanguage = "en";
    }
    return localLanguage;
}

let os = function () {
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
}();

export let isMobile = function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true;
    }
    return false;
}();

export let isAndroid = function () {
    if (os.isAndroid || (navigator.userAgent.toLowerCase().indexOf("android") !== -1)) {
        return true;
    }
    return false;
}();

export let isIOS = function () {
    let str = navigator.userAgent.toLowerCase();
    if (os.isPhone || (str.indexOf("iphone") !== -1) || str.indexOf("ipad") !== -1) {
        return true;
    }
    return false;
}();

export function uuid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 32) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function getImgPath(name) {
    if(isEmpty(name)){
        return '';
    }

    if (name.indexOf('game-cdn.aplusjapan-game.com') != -1) {
        return name;
    }

    return 'https://game-cdn.aplusjapan-game.com/aplus_payment/img/' + name;
}

//------keys------
export const RID = "_apj_rid";
export const Country = "_apj_country";
export const Language = "_apj_language";

export const AppKey = "_apj_pay_appKey";
export const AppChannelKey = "_apj_pay_channelKey";
export const GameId = "_apj_pay_gameId";
