import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Stack, Typography } from '@mui/material';
import * as LKey from '../tools/languageKey';
import * as APJHttp from '../tools/APJHttp';
import { toastActions } from '../store/reducers/ToastSlice';
import { loadingActions } from '../store/reducers/LoadingSlice';

import { paymentActions, paymentSlice } from '../store/reducers/PaymentSlice';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';

import SessionTitle from './SessionTitle';
import CheckIdItem_OL from '../pages/ol/CheckIdItem_OL';
import PayWayItem_OL from '../pages/ol/PayWayItem_OL';
import ProductItem_OL from '../pages/ol/ProductItem_OL';


export default function Payment_OL(props) {

    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.paymentData);
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {
        document.body.style.backgroundColor = 'white';

        APJHttp.requestProductList((data) => {
            dispatch(paymentActions.setProductList(data));
        }, (code, msg) => {
            dispatch(paymentActions.setProductList({}));
        });
    }, []);

    function handleProductTap(index) {
        dispatch(paymentActions.setSelectedProductIndex(index));
    }

    return (
        <Stack
            sx={{
                width: 1,
                backgroundSize: 'cover',
                backgroundImage: settingData.isMobile ? `url(${getImgPath('overlord/pay_mb.png')})` : `url(${getImgPath('overlord/bg_ol_pay.jpg')})`,
                backgroundRepeat: 'no-repeat',
                alignItems: 'center',
                backgroundPosition: 'top center',
            }}>

            <Box component={'img'}
                src={getImgPath('overlord/logo_new.png')}
                sx={{
                    top: '10rem',
                    left: '40rem',
                    height: settingData.isMobile ? '70rem' : '80rem',
                    position: 'absolute',
                    zIndex: 3,
                }} />

            <Stack sx={{
                width: 1,
                overflow: 'auto',
                alignItems: 'center',
                position: 'relative',
            }}>
                <Box component={'img'}
                    src={settingData.isMobile ? getImgPath('overlord/top_ol_mb.png') : getImgPath('overlord/top_ol_pc.png')}
                    sx={{
                        width: 1,
                    }} />

                <Stack sx={{
                    width: 1,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    alignItems: 'center',
                    position: 'relative',

                }}>
                    <Stack sx={{
                        width: settingData.isMobile ? 1 : '1300rem',
                        maxWidth: settingData.isMobile ? 1 : '1300rem',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        overflow: 'hidden',
                        mt: settingData.isMobile ? 10 : 0,
                        left: settingData.isMobile ? 100 : 'auto',
                    }}>

                        <Box component={'img'}
                            src={getImgPath('overlord/girle.png')}
                            sx={{
                                width: settingData.isMobile ? '650rem' : '650rem',
                            }} />
                    </Stack>

                    <Grid container
                        spacing={1}
                        sx={{
                            width: settingData.isMobile ? 0.9 : '900rem',
                            maxWidth: settingData.isMobile ? 0.9 : '900rem',
                            pb: 2,
                            zIndex: 2,
                            mt: -4,
                        }} >

                        {/* 1. 输入ID */}
                        <Grid item xs={settingData.isMobile ? 12 : 6}>
                            <SessionTitle title='Player ID' />
                            <CheckIdItem_OL />
                        </Grid>

                        {/* 2. 支付渠道 */}
                        <Grid item xs={12}>
                            <SessionTitle title={'Payment Method'} />
                        </Grid>
                        <Grid item xs={settingData.isMobile ? 6 : 3.5}>
                            <PayWayItem_OL />
                        </Grid>

                        {/* 3. 商品列表 */}
                        <Grid item xs={12}>
                            <SessionTitle title='Select Product' />
                        </Grid>
                        {
                            paymentData.productList.map((data, index) =>
                                <Grid key={index} item xs={settingData.isMobile ? 6 : 3}>
                                    <ProductItem_OL index={index} data={data} selectedProduct={paymentData.selectedProduct} onClick={handleProductTap} />
                                </Grid>
                            )
                        }

                        {/* 4. 产品数量 */}
                        <Grid item xs={12}>
                            <SessionTitle title='Quantity' />
                            <QuantityItem_OL />
                        </Grid>
                    </Grid>

                    <Box component={'img'}
                        src={getImgPath('overlord/bottom_ol.png')}
                        sx={{
                            bottom: 0,
                            width: 1,
                            position: 'absolute',
                            zIndex: 1,
                        }} />
                </Stack>
            </Stack>
        </Stack>

    );
}


function QuantityItem_OL() {
    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.paymentData);
    const settingData = useSelector(state => state.settingData);
    const checkIdData = useSelector(state => state.checkIdData);
    const [open, setOpen] = React.useState(false);

    function showLimitAlert() {
        setOpen(true);
    };

    function hideLimitAlert() {
        setOpen(false);
    };

    function minusTap() {
        dispatch(paymentActions.quantityMinus());
    }

    function plusTap() {
        dispatch(paymentActions.quantityPlus());
    }

    //点击购买
    function buyTap() {
        if (checkIdData.isSuccess && paymentData.selectedProduct && paymentData.roleId) {
            window.gtag("event", 'ol_payment_tap_start'); //GG埋点 点击购买
            showLoading();
            APJHttp.requestPaymentUrl(
                paymentData.payWayValue,
                paymentData.roleId,
                paymentData.selectedProduct,
                paymentData.quantity,
                getResultUrl(),
                (paymentUrl) => {
                    hideLoading();
                    if(!StringTool.isEmpty(paymentUrl)){
                        window.gtag("event", 'ol_payment_get_url_success'); //GG埋点 跳转支付网站页面
                        setTimeout(() => {
                            window.location.assign(paymentUrl);
                        }, 100);
                    }

                }, (code, msg) => {
                    hideLoading();
                    if(code == 15021 || code == 15019){ //订单被限制
                        showLimitAlert();
                    }else{
                        window.gtag("event", 'ol_payment_get_url_fail'); //GG埋点 支付网站url请求失败
                        dispatch(toastActions.showError(msg));
                    }
                });
        }
    }

    function getResultUrl() {
        return window.location.origin + '/queryResult';
    }

    function showLoading() {
        dispatch(loadingActions.showLoading());
    }

    function hideLoading() {
        dispatch(loadingActions.hideLoading());
    }

    return (
        <Stack sx={{
            width: 1,
            mt: 1.5,
            mb: 5,
            display: 'flex',
            flexDirection: 'row',
        }}>
            {/* 1.添加删除 */}
            <Stack sx={{
                flexDirection: 'row',
                height: '60rem',
                alignItems: 'center',
            }}>
                <Box
                    component={'img'}
                    onClick={minusTap}
                    src={getImgPath('overlord/jian.png')}
                    sx={{
                        width: '20rem',
                        height: '20rem',
                        px: 2,
                        py: 1,
                        bgcolor: 'white',
                        cursor: 'pointer',
                    }} />

                <Typography sx={{
                    mx: 3,
                    color: 'white',
                    fontSize: settingData.isMobile ? '45rem' : '35rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontFamily: window.MyFontA,
                }}>
                    {paymentData.quantity}
                </Typography>

                <Box
                    component={'img'}
                    onClick={plusTap}
                    src={getImgPath('overlord/jia.png')}
                    sx={{
                        width: '20rem',
                        height: '20rem',
                        px: 2,
                        py: 1,
                        bgcolor: 'white',
                        cursor: 'pointer',
                    }} />
            </Stack>

            {/* 2.提交企鹅按钮 */}
            <Stack sx={{
                width: 1,
                flexDirection: 'row',
                justifyContent: 'flex-end',
                position: 'relative',
            }}>
                <Box
                    component={'img'}
                    src={getImgPath('overlord/qier.png')}
                    sx={{
                        height: settingData.isMobile ? '300rem' : '200rem',
                        mr: '50rem',
                    }} />

                <Typography
                    onClick={buyTap}
                    sx={{
                        userSelect: 'none',
                        fontSize: settingData.isMobile ? '40rem' : '35rem',
                        fontFamily: window.MyFontA,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundSize: '100% 100%',
                        backgroundImage: `url(${getImgPath('overlord/round.png')})`,
                        backgroundRepeat: 'no-repeat',
                        alignSelf: 'center',
                        position: 'absolute',
                        width: settingData.isMobile ? '250rem' : '180rem',
                        height: settingData.isMobile ? '150rem' : '100rem',
                        lineHeight: settingData.isMobile ? '150rem' : '100rem',
                        bottom: 0,
                        right: '40rem',
                        cursor: 'pointer',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        },
                        color: (checkIdData.isSuccess && paymentData.selectedProduct) ? 'white' : 'gray',

                    }}>
                    Buy Now
                </Typography>

            </Stack>

            {/* 提示框 */}
            <Dialog
                open={open}
                onClose={hideLimitAlert}>
                <Stack sx={{
                    maxWidth: '450px',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#FDEDED',
                    color: 'black',
                    p: settingData.isMobile ? 3 : 5,
                    fontSize: settingData.isMobile ? '15px':'20px',
                    fontWeight: 'bold',
                }}>
                   Creation of the order failed. The purchase limit has been exceeded.
                </Stack>
            </Dialog>

        </Stack>
    );
}
