import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store/Store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../src/store/reducers/SettingSlice';
import $ from 'jquery';
import * as rdd from 'react-device-detect';
import { Box, Stack, Typography } from '@mui/material';
import * as StringTool from '../src/tools/StringTool';

import HomePage from './pages/HomePage';
import PaymentPage from './pages/PaymentPage';
import SecondPage from './pages/SecondPage';
import LoadingPage from './pages/LoadingPage';
import QueryResultPage from './pages/QueryResultPage';
import PayResultPage from './pages/PayResultPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AppWraper pc={<HomePage />} mb={<HomePage />} />} />
        <Route path='*' element={<AppWraper pc={<HomePage />} mb={<HomePage />} />} />

        <Route path='/paymentList' element={ <AppWraper pc={<PaymentPage />} mb={<PaymentPage />} />} />
        <Route path='/loading' element={<AppWraper pc={<LoadingPage />} mb={<LoadingPage />} />} />
        <Route path='/queryResult' element={<AppWraper pc={<QueryResultPage />} mb={<QueryResultPage />} /> } />
        <Route path='/payResultPage' element={<AppWraper pc={<PayResultPage />} mb={<PayResultPage />} />} />
        <Route path='/secondPage/:teamId' element={<AppWraper pc={<SecondPage lang='en' />} mb={<SecondPage lang='en' />} /> } />
        
      </Routes>
    </BrowserRouter>
  </Provider>
);



function AppWraper(props) {
  const dispatch = useDispatch();
  let resizeTimer;

  React.useEffect(() => {
      $(window).on("resize", changeSize);
      dispatch(settingActions.setIsMobile(isMobile()));
  }, []);

  function changeSize(e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
          dispatch(settingActions.refreshInnerHeight());
          dispatch(settingActions.setIsMobile(isMobile()));
      }, 250);
  }

  function isMobile() {
      return StringTool.isMobile || rdd.isMobile || rdd.isIPad13 || rdd.isIPhone13 || window.innerWidth <= 600;
  }

  return (
      <Stack>
          {isMobile() && props.mb}
          {!isMobile() && props.pc}
      </Stack>
  )
}